import React, { useContext, useEffect } from 'react';
import { useQuery } from '@apollo/client';

import PRODUCT_QUERY from '../queries/product.graphql';
import ProductPageHub from '../productPage/ProductPageHub';

import { CheckoutContext } from '../contexts/checkout';

export default function ProductPage({ id, pageContext, location }) {
  let backupId = '';
  if (!id && location) {
    backupId = location.pathname.substring(10);
  }
  const { currentRetailer } = useContext(CheckoutContext);
  const { loading, error, data } = useQuery(PRODUCT_QUERY, {
    variables: {
      retailerId: currentRetailer,
      id: id || backupId,
    },
    fetchPolicy: 'network-only',
  });
  console.log('Window', loading);

  if (typeof window === 'undefined') {
    const tempdata = {
      product: pageContext.product,
    };
    return (
      <>
        <ProductPageHub data={tempdata} loading={false} error={false} />
      </>
    );
  }
  return (
    <>
      <ProductPageHub data={data} loading={loading} error={error} />
    </>
  );
}
