import React, {useState} from 'react';
import styled from 'styled-components';

const MOBILE_WIDTH = '900px';

const AboutThisProductStyle = styled.div`
    margin: 10px 30px;

    h4{
        font-family: Oswald;
        font-style: normal;
        font-weight: 500;
        font-size: 36px;
        text-align: center;
        padding-bottom: 30px;
    }

    .details{
        display: grid;
        grid-template-rows: repeat(6, 70px);
        padding: 20px 0px;
    }

    .detail-row{
        display: flex;
        justify-content: flex-start;
        align-items: center;

        border-bottom: 1px solid #B1B2B3;

        .label{
            font-family: Lato;
            font-style: normal;
            font-weight: 900;
            font-size: 20px;
            width: 40%;
        }

        .info{
            font-family: Lato;
            font-style: normal;
            font-weight: normal;
            font-size: 18px;
            display: flex;
            justify-content: flex-start;
            text-transform: capitalize;
            flex-wrap: wrap;
        }
        
        .singleEffect{
            padding-right: 5px;
            text-transform: capitalize;
        }
    }

    @media (max-width: ${MOBILE_WIDTH}){
        .description {
            font-size: 14px;
        }

        .detail-row{
            .label{
                font-size: 15px;
            }
            .info{
                font-size: 14px;
            }
        }
    }
`;

export default function AboutThisProduct({description, brand, thc, cbd, strainType, effect}){
    const formattedEffects=[];
    effect.map((eff, key) => (
        formattedEffects.push(eff.toLowerCase())
    ))

    return(
        <AboutThisProductStyle>
                <h4>About this Product</h4>
                <div className="description">
                    {description}
                </div>
                <div className="details">

                    <div className="detail-row">
                        <div className="label">BRAND</div>
                        <div className="info">{brand}</div>
                    </div>

                    <div className="detail-row">
                        <div className="label">THC</div>
                        <div className="info">{thc}</div>
                    </div>        

                    <div className="detail-row">
                        <div className="label">CBD</div>
                        <div className="info">{cbd}</div>
                    </div>        

                    <div className="detail-row">
                        <div className="label">SRAIN TYPE</div>
                        <div className="info">{strainType.toLowerCase()}</div>
                    </div>        

                    <div className="detail-row">
                        <div className="label">EFFECTS</div>
                        <div className="info">
                            {formattedEffects.map((eff, index) => (
                                (index === formattedEffects.length-1)
                                ?
                                <div className="singleEffect" key={index}>{eff}</div>
                                :
                                <div className="singleEffect" key={index}>{eff},</div>
                            ))}
                        </div>
                    </div>
                </div>
            </AboutThisProductStyle>
    )
}