import React from 'react';
import { Router } from '@reach/router';

import Menu from './menu';
import ProductPage from '../components/apolloMenu/templates/ProductPage';

const Products = () => (
  <div>
    <Router>
      <Menu path="/menu" />
      <ProductPage path="/products/:id" />
    </Router>
  </div>
);

export default Products;
