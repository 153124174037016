/* eslint-disable eqeqeq */
/* eslint-disable camelcase */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useState, useEffect, useContext } from 'react';
import styled from 'styled-components';
import Img from 'react-cool-img';
import Select from 'react-select';
import { toast } from 'react-toastify';

import { CheckoutContext } from '../contexts/checkout';

import ProductDetails from './ProductDetails';
import QuantityWidget from './QuantityWidget';
import AboutThisProduct from './AboutThisProduct';
import Preloader from './Preloader';
import SEO from '../../SEO/SEO';

import { addToCartSurf, viewProducttSurf } from '../../../hooks/useSurfside';

import ProductPromoContext from '../../../contexts/ProductPromoContext';

const MOBILE_WIDTH = '900px';

const ProductWrapper = styled.div`
  padding: 100px;
  @media (max-width: 800px) {
    padding: 5px;
  }
`;

const ProductPageGrid = styled.div`
  max-width: 900px;
  margin: 10px auto;
  display: grid;
  grid-template-columns: 385px 5.5vw 150px 150px 150px;
  grid-template-rows: 365px 75px 75px 80px;
  grid-gap: 7px;
  @media (max-width: ${MOBILE_WIDTH}) {
    margin: 0px 0px;
    padding: 10px;
    grid-template-columns: repeat(10, 1fr);
    grid-template-rows: 150px 10px 75px 80px;
    grid-gap: auto;
  }
`;

const LabelWithBlock = styled.div`
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;
  width: 100%;
  .label {
    text-transform: uppercase;
    font-family: Oswald;
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    padding-bottom: 8px;
  }
`;

const ProductImage = styled.div`
  grid-column: 1;
  grid-row: 1 / 4;
  background-color: white;
  border-radius: 5px;
  display: flex;
  justify-content: center;
  padding: 10px;
  @media (max-width: ${MOBILE_WIDTH}) {
    grid-row: 1 / 4;
    grid-column: 1 / 5;
    margin-right: 10px;
    margin-bottom: 5px;
  }
  .img-obj {
    object-fit: contain;
  }
`;

const ProductDetailWrapper = styled.div`
  grid-column: 3 / 10;
  grid-row: 1;
  @media (max-width: ${MOBILE_WIDTH}) {
    grid-row: 1 / 4;
    grid-column: 5 / 11;
  }
`;

const WeightWrapper = styled.div`
  grid-row: 2;
  grid-column: 3;
  .weightSelect {
    width: 100%;
    font-size: 16px;
  }
  @media (max-width: ${MOBILE_WIDTH}) {
    grid-row: 4;
    grid-column: 1 / 4;
  }
`;

const QuantityWrapper = styled.div`
  grid-row: 2;
  grid-column: 4;
  @media (max-width: ${MOBILE_WIDTH}) {
    grid-row: 4;
    grid-column: 7 / 9;
  }
`;

const PriceWrapper = styled.div`
  grid-row: 2;
  grid-column: 5;
  .price {
    font-family: Lato;
    color: var(--darkgreen);
    font-weight: 800;
  }
  .sale-price-warp {
    display: flex;
  }

  .strike-through {
    text-decoration: line-through;
    color: gray;
    opacity: 0.6;
    padding-right: 5px;
  }
  @media (max-width: ${MOBILE_WIDTH}) {
    grid-row: 5;
    grid-column: 1;
    .price {
      margin-top: -5px;
      font-size: 23px;
      font-weight: 900;
    }
  }
`;

const AddToCartWrapper = styled.div`
  grid-row: 3;
  grid-column: 3 / 5;
  .hoc-button {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    &:hover {
      outline: 3px solid var(--darkgreen);
    }
  }
  .limit-hit {
    font-family: Oswald;
    justify-content: center;
    align-items: center;
    text-align: center;
    //outline: solid 2px grey;
    color: white;
    border-radius: 5px;
    background: grey;
    padding: 4px 0px;
  }
  @media (max-width: ${MOBILE_WIDTH}) {
    grid-row: 5;
    grid-column: 3 / 11;
    padding-left: 20px;
    .hoc-button {
      height: 100%;
    }
  }
`;

const WrongLocationText = styled.div`
  grid-row: 2;
  grid-column: 3 / 7;
  .warning {
    font-family: Lato;
    font-weight: 800;
    font-size: 22px;
  }
  .subtext {
    margin-top: 10px;
    .location {
      font-weight: 600;
    }
  }
  @media (max-width: ${MOBILE_WIDTH}) {
    grid-row: 4 / 6;
    grid-column: 1 / 11;
    text-align: center;
  }
`;

const Locations = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
  .location {
    padding: 0px 30px;
    &:hover {
      cursor: pointer;
      text-decoration: underline;
    }
    &.active {
      font-weight: 900;
    }
  }
  border-radius: 20px;
  padding: 50px 50px;
  margin: 30px 0px;
  margin-top: 0px;

  @media (max-width: ${MOBILE_WIDTH}) {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 50px 0px;
    margin: 30px 0px;
    margin-top: 0px;
    padding-top: 5px;
  }
`;

const LocationTitle = styled.div`
  display: flex;
  justify-content: center;
  text-align: center;
  width: 100%;

  h3 {
    font-family: Oswald;
    color: black;
    font-size: 25px;
    text-align: center;
  }
  @media (max-width: ${MOBILE_WIDTH}) {
    margin-top: 30px;
  }
`;

const AboutThisProductWrapper = styled.div`
  max-width: 1000px;
  margin: auto;
  display: flex;
  justify-content: center;
`;

const Center = styled.div`
  width: 100%;
  height: 80vh;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const WrongLocation = styled.div`
  grid-row: 2;
  grid-column: 3 / 10;
  padding: 10px 0px;
  font-family: Oswald;
  color: #a32e2e;
  @media (max-width: ${MOBILE_WIDTH}) {
    grid-row: 5;
    grid-column: 3 / 11;
  }
`;

function priceMaker(retailerId, product, option, quantity = 1) {
  const { variants } = product;
  if (variants) {
    const price =
      variants.filter((x) => x.option === option)[0].price_rec * quantity;
    return parseFloat(price);
  }
}

function PriceMakerSale({ product, option, quantity = 1 }) {
  try {
    const { variants } = product;
    if (variants) {
      const price =
        variants.filter((x) => x.option === option)[0]?.priceRec * quantity;
      const special_price =
        variants.filter((x) => x.option === option)[0]?.specialPriceRec *
        quantity;
      if (special_price) {
        return (
          <div className="sale-price-warp">
            <div className="strike-through">
              $ {parseFloat(price).toFixed(2)}
            </div>
            <div>$ {parseFloat(special_price).toFixed(2)}</div>
          </div>
        );
      }
      return <div>$ {parseFloat(price).toFixed(2)}</div>;
    }
  } catch {
    return 0;
  }
}

const ProductPageHub = ({ data, loading, error }) => {
  const notify = () =>
    toast.success('Added to cart!', {
      position: 'bottom-center',
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: false,
      draggable: false,
      progress: undefined,
    });

  // const [modalYes, setModalYes] = useState(false);
  // const [modalOn, setModalOn] = useState(false);
  // const [modalOption, setModalOption] = useState();
  const [quantity, setQuantity] = useState(1);
  const [variant, setVariant] = useState();
  // const [newSlug, setNewSlug] = useState();

  const {
    isInLimit,
    prodLimit,
    isInPromoLimitProducts,
    remainingLimit,
    preRollCount,
    increaseProductCount,
  } = useContext(ProductPromoContext);
  // const [wrongLocation, setWrongLocation] = useState(false);
  // const [refreshTrigger, setRefreshTrigger] = useState(false);

  useEffect(() => {
    if (data){
      setTimeout(viewProducttSurf, 1000, data.product, data.product.variants[0].option);
    }
  }, [data]);

  // Weight Dropdown Styless
  const weightSelectStyle = {
    singleValue: (provided, state) => ({
      ...provided,
      height: 20,
    }),
    dropdownIndicator: (provided, state) => ({
      ...provided,
      color: '#000000',
    }),
    indicatorSeparator: (provided, state) => ({
      ...provided,
      display: 'none',
    }),
  };
  const { addToCart, currentRetailer } = useContext(CheckoutContext);
  let options = [];
  if (!loading) {
    options = data?.product.variants.map((v) => ({
      label: v.option,
      value: v.option,
    }));
  }
  if (error) {
    return <h1>Error</h1>;
  }
  return (
    <>
      <ProductWrapper>
        {loading ? (
          <Center>
            <Preloader />
          </Center>
        ) : (
          <>
            <SEO
              title={`${data.product.name} - Products`}
              description={data.product.description}
              image={data.product.image}
            />
            <ProductPageGrid>
              <ProductImage>
                <Img
                  placeholder="bag.svg"
                  error="bag.svg"
                  src={data.product.image}
                  alt={data.product.name}
                  className="img-obj"
                />
              </ProductImage>

              <ProductDetailWrapper>
                <ProductDetails
                  title={data.product.name}
                  brand={data.product?.brand?.name}
                  thc={data.product.potencyThc.formatted}
                  cbd={data.product.potencyCbd.formatted}
                  effect={data.product.effects}
                  strainType={data.product.strainType}
                  reviewScore={0}
                />
              </ProductDetailWrapper>

              <>
                <WeightWrapper>
                  <LabelWithBlock>
                    <div className="label">weight</div>
                    <Select
                      className="weightSelect"
                      options={options}
                      defaultValue={
                        options[0].label.toLowerCase() === 'n/a'
                          ? ''
                          : options[0]
                      }
                      styles={weightSelectStyle}
                      onChange={(opt) => setVariant(opt.value)}
                      isSearchable={false}
                      isDisabled={options[0].label.toLowerCase() == 'n/a'}
                      placeholder=""
                    />
                  </LabelWithBlock>
                </WeightWrapper>

                {typeof window !== 'undefined' && (
                  <QuantityWrapper>
                    <LabelWithBlock>
                      <div className="label">quantity</div>
                      <QuantityWidget
                        quantity={quantity}
                        setQuantity={setQuantity}
                        qlimit={
                          isInPromoLimitProducts(data.product.id)
                            ? remainingLimit()
                            : 9
                        }
                        oglimit={prodLimit}
                      />
                    </LabelWithBlock>
                  </QuantityWrapper>
                )}

                <PriceWrapper>
                  <LabelWithBlock>
                    <div className="label">price</div>
                    <div className="price">
                      <PriceMakerSale
                        product={data.product}
                        option={variant || options[0].value}
                        quantity={quantity}
                      />
                    </div>
                  </LabelWithBlock>
                </PriceWrapper>

                {typeof window !== 'undefined' && (
                  <AddToCartWrapper>
                    {!isInLimit(data.product.id) ? (
                      <div className="limit-hit">LIMIT REACHED</div>
                    ) : (
                      <div
                        className="hoc-button"
                        onClick={() => {
                          const option =
                            variant || data.product.variants[0].option;
                          const correctId = data.product.id;
                          notify();
                          addToCart(correctId, quantity, option);
                          increaseProductCount(quantity, data.product.id);
                          addToCartSurf(data.product, option, quantity);
                        }}
                      >
                        Add To Cart
                      </div>
                    )}
                  </AddToCartWrapper>
                )}
              </>
            </ProductPageGrid>

            {/* <LocationTitle>
              <h3>Available At</h3>
            </LocationTitle>
            <CheckoutContext.Consumer>
              {({ currentRetailer, setCurrentRetailer }) => (
                <>
                  <Locations>
                    {data.product.stores.map((store, index) => (
                      <>
                        <LocationCard
                          key={index}
                          name={store.slice(24)}
                          address={data.product.store_addys[index]}
                          active={currentRetailer === data.product.store_ids[index]}
                          storeid={data.product.store_ids[index]}
                          setModalOn={setModalOn}
                          setModalOption={setModalOption}
                          setNewSlug={setNewSlug}
                          slug={data.product.slugs[index]}
                          refreshTrigger={refreshTrigger}
                          setRefreshTrigger={setRefreshTrigger}
                        />
                      </>
                    ))}
                  </Locations>

                  {typeof window !== 'undefined' && (
                    <LocationModal
                      setCurrentRetailer={setCurrentRetailer}
                      modalYes={modalYes}
                      setModalYes={setModalYes}
                      modalOn={modalOn}
                      setModalOn={setModalOn}
                      option={modalOption}
                      slug={newSlug}
                    />
                  )}
                </>
              )}
            </CheckoutContext.Consumer> */}

            <AboutThisProductWrapper>
              <AboutThisProduct
                description={data.product.description}
                brand={data.product?.brand?.name}
                thc={data.product.potencyThc.formatted}
                cbd={data.product.potencyCbd.formatted}
                strainType={data.product.strainType}
                effect={data.product.effects}
              />
            </AboutThisProductWrapper>
          </>
        )}
      </ProductWrapper>
    </>
  );
};

export default ProductPageHub;
