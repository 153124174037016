import React, { useState } from 'react';
import styled from 'styled-components';
import ReviewStars from './ReviewStars';

const MOBILE_WIDTH = '900px';

const ProductInfo = styled.div`
  width: 100%;

  .strainType {
    font-family: Oswald;
    font-size: 15px;
    border-radius: 3px;
    text-transform: capitalize;
    padding: 9px 45px;
    font-weight: 500;
    display: inline-block;
    margin-bottom: 20px;
  }

  .hybrid {
    background-color: rgba(24, 118, 242, 0.2);
    color: #1877f2;
  }
  .indica {
    background-color: rgba(161, 198, 83, 0.2);
    color: #a1c654;
  }
  .sativa {
    background-color: rgba(28, 196, 95, 0.2);
    color: #1cc25f;
  }

  h2 {
    font-family: Oswald;
    font-weight: normal;
    font-size: 24px;
    text-transform: uppercase;
    opacity: 0.5;
    color: black;
    letter-spacing: 0.1em;

    margin: 10px 0px;
  }

  h1 {
    font-family: Oswald;
    font-weight: bold;
    font-size: 36px;
    text-transform: none;
    color: black;

    margin: 10px 0px;
    margin-bottom: 15px;
  }

  .potency {
    display: flex;
    margin: 10px 0px;
  }
  .potency-block {
    color: #969595;
    font-weight: 500;
    font-size: 14px;
    background: #ffffff;
    border: 1px solid #c4c4c4;
    box-sizing: border-box;
    border-radius: 2px;
    padding: 13px;
    margin-right: 16px;
    letter-spacing: -1px;

    width: 120px;

    display: flex;
    justify-content: center;

    .label {
      display: inline-block;
      font-weight: 700;
      margin-right: 3px;
    }
  }

  .effectWapper {
    padding: 20px 0px;

    display: flex;
    justify-content: flex-start;
    font-family: Oswald;
    font-style: normal;
    font-weight: 500;
    font-size: 15px;
    line-height: 100%;

    .label {
      font-weight: 500;
      margin-right: 3px;
    }

    .singleEffect {
      padding-left: 3px;
      text-transform: capitalize;
    }
  }

  .reviewPreview {
  }

  hr {
    opacity: 0.1;
    color: #b1b2b3;
  }

  @media (max-width: ${MOBILE_WIDTH}) {
    width: auto;

    .strainType {
      font-size: 12px;
      padding: 4px 15px;
      margin-bottom: 5px;
    }

    h2 {
      font-size: 14px;
    }
    h1 {
      font-size: 16px;
    }

    .potency {
      flex-direction: column;
      margin-bottom: 0px;
    }
    .potency-block {
      font-size: 12px;
      padding: 10px;
      margin-bottom: 8px;
      width: 120px;
    }

    .effectWapper {
      padding: 10px 0px;
      padding-bottom: 4px;
      font-size: 12px;
      max-width: 150px;
      flex-flow: wrap;

      .label {
        font-weight: 700;
      }
    }

    .reviewsPreview {
      .star {
        font-size: 11px;
        height: 11px;
      }
    }
  }
`;

export default function ProductDetails({
  brand,
  title,
  thc,
  cbd,
  effect,
  strainType,
  reviewScore,
}) {
  const formattedEffects = [];
  effect.map((eff, key) => formattedEffects.push(eff.toLowerCase()));
  const strainNAStrings = ['not_applicable', 'n/a'];

  return (
    <ProductInfo>
      <div className={`strainType ${strainType.toLowerCase()}`}>
        {strainNAStrings.includes(strainType.toLocaleLowerCase())
          ? ''
          : strainType.toLowerCase()}
      </div>

      <h2>{brand}</h2>
      <h1>{title}</h1>

      <div className="potency">
        {thc && (
          <div className="potency-block">
            <div className="label">THC:</div>
            {thc}
          </div>
        )}
        {cbd && (
          <div className="potency-block">
            <div className="label">CBD:</div>
            {cbd}
          </div>
        )}
      </div>

      <div className="effectWapper">
        <div className="label">Effects:</div>
        {formattedEffects.map((eff, index) =>
          index === formattedEffects.length - 1 ? (
            <div className="singleEffect" key={index}>
              {eff}
            </div>
          ) : (
            <div className="singleEffect" key={index}>
              {eff},
            </div>
          )
        )}
      </div>

      <div className="reviewsPreview">
        <ReviewStars stars={reviewScore} />
      </div>

      <hr />
    </ProductInfo>
  );
}
