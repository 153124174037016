import React from 'react';
import styled from 'styled-components';
import { BsStarFill, BsStarHalf } from 'react-icons/bs';

export default function ReviewStars({ stars }) {
  const starsArr = [];
  for (let i = 0; i < Math.floor(stars); i++) {
    starsArr.push(<BsStarFill key={i} className="star" />);
  }
  if (Math.floor(stars) != stars) {
    starsArr.push(<BsStarHalf key={Math.floor(stars)} className="star" />);
  }
  return <div className="stars">{starsArr}</div>;
}
